import React from 'react'
import { func, string } from 'prop-types';
import styled from "styled-components"
import SettingsBrightnessIcon from '@material-ui/icons/SettingsBrightness';

const Button = styled.button`
    background: ${({ theme }) => theme.background};
    border: 1px solid ${({ theme }) => theme.toggleBorder};
    color: ${({ theme }) => theme.text};
    border-radius: 10px;
    cursor: pointer;
    height: 30px;
  }
`;

const Toggle = ({theme,  toggleTheme }) => {
    return (
        <Button onClick={toggleTheme} >
          Switch Theme
        </Button>
    );
};

Toggle.propTypes = {
    theme: string.isRequired,
    toggleTheme: func.isRequired,
}

export default Toggle;