import React, { useState, useEffect, useRef } from 'react';
import './ChatScreen.css';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import db from './firebase';
import Dm from './Dm';
import { useSelector } from 'react-redux';
import { selectUser } from './features/userSlice';
import firebase from 'firebase';
import { useHistory } from 'react-router-dom';
import SendIcon from '@material-ui/icons/Send';
import TextField from '@material-ui/core/TextField';
import './Dm.css';
import { useParams } from 'react-router-dom';

function ChatScreen() {
	const user = useSelector(selectUser);
	const [input, setInput] = useState('');
	const [messages, setMessages] = useState([]);
	const history = useHistory();
	const { roomId } = useParams();
	const [roomName, setRoomName] = useState('');

	//logic to send a message
	const sendMessage = (e) => {
		e.preventDefault();

		console.log(roomId);
		db.collection('users')
			.doc(`@${user.displayName}`)
			.collection('messagesWith')
			.doc(roomId)
			.collection('messages')
			.add({
				username: user.uid,
				displayName: user.displayName,
				text: input,
				avatar: user.photo,
				timestamp: firebase.firestore.FieldValue.serverTimestamp(),
			});
		setInput('');
	};

	useEffect(() => {
		if (roomId) {
			db.collection('users')
				.doc(`@${user.displayName}`)
				.collection('messagesWith')
				.doc(roomId)
				.collection("messages")
				.orderBy('timestamp', 'asc')
				.onSnapshot((snapshot) => {
					setMessages(snapshot.docs.map((doc) => doc.data()));
				});
		}
	}, [roomId]);

	const messagesEndRef = useRef(null);

	const scrollToBottom = () => {
		messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
	};

	useEffect(() => {
		scrollToBottom();
	}, [messages]);

	return (
		<div className="chatScreen">
			<div className="chatScreen__header">
				<IconButton
					onClick={() => history.replace('/messages')}
					className="chatScreen__back"
				>
					<ArrowBackIcon className="chatScreen__backButton" fontSize="medium" />
				</IconButton>
			</div>

			{messages.map((message) => (
				<Dm
					key={message.id}
					dm={message.text}
					avatar={message.avatar}
					username={message.username}
				/>
			))}
			<div ref={messagesEndRef} />
			<div className="chatScreen__form">
				<FormControl className="chatScreen__input">
					<InputLabel>Type a message...</InputLabel>
					<Input
						multiline
						rowsMax={6}
						value={input}
						onChange={(e) => setInput(e.target.value)}
						onKeyPress={(e) => (e.key === 'Enter' ? sendMessage(e) : null)}
						text="text"
						className="chatScreen__inputField"
					/>
					<IconButton
						color="primary"
						disabled={!input}
						onClick={sendMessage}
						type="submit"
						className="chatScreen__inputButton"
					>
						<SendIcon />
					</IconButton>
				</FormControl>
			</div>
		</div>
	);
}

export default ChatScreen;
