import React, { useState, useEffect } from 'react';
import PostBox from './PostBox';
import Post from './Post';
import './Feed.css';
import db from './firebase';
import FlipMove from 'react-flip-move';

function Feed() {
	const [posts, setPosts] = useState([]);

	useEffect(() => {
		db.collection('posts')
			.orderBy('timestamp', 'desc')
			.onSnapshot((snapshot) =>
				setPosts(snapshot.docs.map((doc) => ({ id: doc.id, post: doc.data() })))
			);
	}, []);

	return (
		<div className="feed">
			<div className="feed__header">
				<h2>Home</h2>
			</div>

			<PostBox />

			<FlipMove>
				{posts.map(({ id, post }) => (
					<Post
						key={id}
						timestamp={post.timestamp}
						displayName={post.displayName || 'Anon'}
						username={post.username || 'Anon'}
						verified={post.verified}
						text={post.text}
						avatar={post.avatar}
						image={post.image}
					/>
				))}
			</FlipMove>
		</div>
	);
}

export default Feed;
