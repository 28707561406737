import { Button } from '@material-ui/core';
import { auth, provider } from './firebase';
import { useWallet, UseWalletProvider } from 'use-wallet';
import React from 'react';
import './Login.css';
import db from './firebase';
import { userSlice } from './features/userSlice';
import { useSelector } from 'react-redux';
import { selectUser } from './features/userSlice';
import firebase from 'firebase';

function LoginAnonymously() {
	auth
		.signInAnonymously()
		.then(() => {})
		.catch((error) => alert(error.message));
}

function LoginWithMetaMask() {
	const wallet = useWallet();

	const connectWallet = async (e) => {
		e.preventDefault();
		await wallet.connect();
		// auth()
		// 	.signInWithCustomToken(token)
		// 	.then((userCredential) => {
		// 		// Signed in
		// 		var user = userCredential.user;
		// 		// ...
		// 	})
		// 	.catch((error) => {
		// 		var errorCode = error.code;
		// 		var errorMessage = error.message;
		// 		// ...
		// 	});
	};

	return (
		<div>
			<div className="login__logo">
				<img
					src="https://d3n8a8pro7vhmx.cloudfront.net/themes/5db7bca4c29480c061890f10/attachments/original/1553643295/login.png?1553643295"
					alt=""
				/>
			</div>
			<Button onClick={connectWallet}>Sign In With MetaMask</Button>
		</div>
	);
}

function Login() {
	const user = useSelector(selectUser);

	function signIn() {
		//login info
		console.log(auth.signInWithPopup(provider));

/* 			.then((addAcc) => addDetails(addAcc))
			.catch((error) => alert(error.message)); */
	}

	const addDetails = () => {
		db.collection('users').add({
			uid: user.uid,
			// verified: false,
			// avatar: user.photo,
			// timestamp: firebase.firestore.FieldValue.serverTimestamp(),
		});
	};

	return (
		<div className="login">
			<div className="login__logo">
				<img
					src="https://d3n8a8pro7vhmx.cloudfront.net/themes/5db7bca4c29480c061890f10/attachments/original/1553643295/login.png?1553643295"
					alt=""
				/>
			</div>

			<Button onClick={LoginAnonymously}>Sign In Anonymously</Button>
			<Button onClick={signIn}>Sign In</Button>
			<Button onClick={addDetails}>Create Account</Button>
		</div>
	);
}

export default () => (
	<UseWalletProvider
		chainId={1}
		connectors={{
			// This is how connectors get configured
			provided: { provider: window.cleanEthereum },
		}}
	>
		<LoginWithMetaMask />
		<Login />
	</UseWalletProvider>
);
