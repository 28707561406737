import React, { useState, useEffect } from 'react';
import './Chat.css';
import { Avatar } from '@material-ui/core';
import { Link } from 'react-router-dom';
import db from './firebase';
import { useParams } from 'react-router-dom';

function Chat({ timestamp, name, id }) {
	const [messages, setMessages] = useState([]);
	const { roomId } = useParams();

	useEffect(() => {
		if (id) {
			db.collection('users')
				.doc(id)
				.collection('messagesWith')
				.orderBy('timestamp', 'desc')
				.onSnapshot((snapshot) => {
					setMessages(snapshot.docs.map((doc) => doc.data()));
				});
		}
	}, [id]);

	return (
		<Link to={`/messages/${id}`} key={id}>
			<div className="chat">
				<div>
					<Avatar className="chat__image" src={messages[0]?.avatar} />
				</div>
				<div className="chat__details">
					<h2>{name}</h2>
					<p>{messages[0]?.text}</p>
				</div>
				<p className="chat__timestamp">
					{new Date(timestamp?.toDate()).toUTCString()}
				</p>
			</div>
		</Link>
	);
}

export default Chat;
