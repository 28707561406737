import React, { useState, useEffect, Component } from 'react';
import Sidebar from './Sidebar';
import Feed from './Feed';
import Widgets from './Widgets';
import { ThemeProvider } from 'styled-components';
import { useDarkMode } from './components/useDarkMode';
import { GlobalStyles } from './components/Globalstyle';
import { lightTheme, darkTheme } from './components/Themes';
import Toggle from './components/Toggler';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from './features/userSlice';
import Login from './Login';
import LoginWithMetaMask from './Login';
import './App.css';
import { auth } from './firebase';
import { login, logout } from './features/userSlice';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Messages from './Messages';
import ChatScreen from './ChatScreen';
import MyContract from './Truffle/build/contracts/MyContract.json';
import getWeb3 from './Web3.js';
import { AlternateEmail } from '@material-ui/icons';
import db from './firebase';
import firebase from 'firebase';

const App = () => {
	const [web3, setWeb3] = useState(undefined);
	const [accounts, setAccounts] = useState(undefined);
	const [contract, setContract] = useState(undefined);
	const [users, setUsers] = useState(undefined);

	useEffect(() => {
		const init = async () => {
			// Get network provider and web3 instance.
			const web3 = await getWeb3();

			// Use web3 to get the user's accounts.
			const accounts = await web3.eth.getAccounts();

			// Get the contract instance.
			const networkId = await web3.eth.net.getId();
			const deployedNetwork = MyContract.networks[networkId];
			const instance = new web3.eth.Contract(
				MyContract.abi,
				deployedNetwork && deployedNetwork.address
			);

			setWeb3(web3);
			setAccounts(accounts);
			setContract(contract);
		};
		init();
	}, [web3, accounts]);

	const dispatch = useDispatch();
	const user = useSelector(selectUser);

	useEffect(() => {
		auth.onAuthStateChanged((authUser) => {
			if (authUser) {
				//the user is logged in
				dispatch(
					login({
						uid: authUser.uid,
						photo: authUser.photoURL,
						email: authUser.email,
						displayName: authUser.displayName,
					})
				);

				db.collection('users').onSnapshot((snapshot) => {
					const userCheck = snapshot.docs.find(
						(doc) => doc.data().uid === authUser.uid
					);
					if (!userCheck && authUser.displayName !== null) {
						db.collection('users').doc(`@${authUser.displayName}`).set({
							uid: authUser.uid,
							verified: false,
							avatar: authUser.photoURL,
							timestamp: firebase.firestore.FieldValue.serverTimestamp(),
						});
					}
				});
			} else {
				//the user is logged out
				dispatch(logout());
			}
		});
	}, [dispatch]);

	const [theme, themeToggler, mountedComponent] = useDarkMode();
	const themeMode = theme === 'light' ? lightTheme : darkTheme;
	if (!mountedComponent) return <div />;

	return (
		// BEM

		<ThemeProvider theme={themeMode}>
			<>
				<GlobalStyles />
				<div className="app">
					{user ? (
						<>
							{/* <Toggle className="switchTheme" theme={theme} toggleTheme={themeToggler}/> */}
							<Router>
								<Sidebar />
								<Switch>
									<Route path="/explore">
										<h1 style={{ minWidth: '320', flex: 0.4 }}>
											I am on explore page
										</h1>
									</Route>
									<Route path="/notifications">
										<h1 style={{ minWidth: '320', flex: 0.4 }}>
											I am on notifications page
										</h1>
									</Route>
									<Route path="/messages/:roomId">
										<ChatScreen />
									</Route>
									<Route path="/messages">
										<Messages />
									</Route>
									<Route path="/profile">
										<h1 style={{ minWidth: '320', flex: 0.4 }}>
											I am on profile page
										</h1>
									</Route>
									<Route path="/settings">
										<h1 style={{ minWidth: '320', flex: 0.4 }}>
											I am on settings page
										</h1>
									</Route>
									<Route path="/comment">
										<h1 style={{ minWidth: '320', flex: 0.4 }}>
											I am on comment page
										</h1>
									</Route>
									<Route path="/">
										{/* Show feed while on homepage */}
										<Feed />
									</Route>
								</Switch>
								<Widgets />
							</Router>
						</>
					) : (
						<LoginWithMetaMask />
					)}
				</div>
			</>
		</ThemeProvider>
	);
};

export default App;
