import React from 'react';
import './Widgets.css';
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import SearchIcon from '@material-ui/icons/Search';

function Widgets() {
	return (
		<div className="widgets">
			<div className="widgets__input">
				<SearchIcon className="widgets__searchIcon" />
				<input
					placeholder="Search"
					type="text"
					className="widgets__inputSearch"
				/>
			</div>

			<div className="widgets__widgetContainer">
				<h2>Browse Popular NFTs</h2>

				<TwitterTimelineEmbed
					sourceType="profile"
					screenName="cryptopunksbot"
					options={{ height: 250 }}
				/>
				<TwitterTimelineEmbed
					sourceType="profile"
					screenName="cryptokitties"
					options={{ height: 250 }}
				/>
			</div>
		</div>
	);
}

export default Widgets;
