import React from "react";
import "./Sidebar.css";
import SidebarOption from "./SidebarOption";
import HomeIcon from "@material-ui/icons/Home";
import SearchIcon from "@material-ui/icons/Search";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { auth } from "./firebase";
import { Link } from "react-router-dom";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

function Sidebar() {

  return (
    <div className="sidebar">
      <Link to="/">
        <SidebarOption active Icon={HomeIcon} text="Home" />
      </Link>
      
      <Link to="/explore">
        <SidebarOption Icon={SearchIcon} text="Explore" />
      </Link>
      
      <Link to="/notifications">
        <SidebarOption Icon={NotificationsNoneIcon} text="Notifications" /> 
      </Link>
      
      <Link to="/messages">
        <SidebarOption Icon={MailOutlineIcon} text="Messages" />
      </Link>
      
      <Link to="/profile">
        <SidebarOption Icon={PermIdentityIcon} text="Profile" />
      </Link>
      
      <Link to="/settings">
        <SidebarOption Icon={MoreHorizIcon} text="More" />
      </Link>
      

      <div className="logout">
          <ExitToAppIcon onClick={() => auth.signOut()} />
      </div>
    </div>

  );
}

export default Sidebar;
