import React from 'react';
import { Avatar } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { selectUser } from './features/userSlice';
import './Dm.css';

function Dm({ dm, avatar, username }) {
	const user = useSelector(selectUser);

	const isUser = user.uid === username;

	return (
		<div className="container">
			<div className={`dm ${!isUser ? 'dm__guestStyle' : 'dm__userStyle'}`}>
				<Avatar
					className={`${!isUser ? 'dm__guestAvatar' : 'dm__userAvatar'}`}
					src={avatar}
				/>
				<div className={`${isUser ? 'dm__userText' : 'dm__guestText'}`}>
					{dm}
				</div>
			</div>
		</div>
	);
}

export default Dm;
