import firebase from 'firebase';

const firebaseConfig = {
	apiKey: 'AIzaSyDJhEHpqhR6WavwpWzf-PwxcmXQXGFnDWQ',
	authDomain: 'social-app-e05bf.firebaseapp.com',
	projectId: 'social-app-e05bf',
	storageBucket: 'social-app-e05bf.appspot.com',
	messagingSenderId: '376697305019',
	appId: '1:376697305019:web:5a3bb61e849a0a9fbdb69e',
	measurementId: 'G-HJC0NG6WNJ',
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();
const auth = firebase.auth();
const provider = new firebase.auth.GoogleAuthProvider();
const storage = firebase.storage();

export { auth, provider, storage };
export default db;
