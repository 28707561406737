import {createGlobalStyle} from "styled-components";

export const lightTheme = {
    body: '#FFF',
    text: 'black',
    toggleBorder: '#FFF',
    background: '#ff7944',
}
export const darkTheme = {
    body: '#1b1f3a',
    text: 'white',
    toggleBorder: '#6B8096',
    background: '#ff7944',
}

export const GlobalStyles = createGlobalStyle`

    body {
        background-color: ${props => props.theme.body};
    }

`;