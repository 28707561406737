import React, { useState, useEffect } from 'react';
import './PostBox.css';
import {
	Avatar,
	Button,
	FormControl,
	InputLabel,
	Input,
	IconButton,
} from '@material-ui/core';
import db from './firebase';
import { useSelector } from 'react-redux';
import { selectUser } from './features/userSlice';
import firebase from 'firebase';
import storage from './firebase';
import CancelIcon from '@material-ui/icons/Cancel';

function PostBox() {
	const user = useSelector(selectUser);
	const [input, setInput] = useState('');
	const [postImage, setPostImage] = useState('');
	const [postFile, setPostFile] = useState('');

	useEffect(() => {
		document.getElementById('cancelImage').style.visibility = 'hidden';
	}, []);

	const sendPost = (e) => {
		e.preventDefault();

		db.collection('posts').add({
			uid: user.uid,
			displayName: user.displayName,
			username: user.displayName,
			verified: true,
			text: input,
			image: postImage,
			avatar: user.photo,
			timestamp: firebase.firestore.FieldValue.serverTimestamp(),
		});

		const storageRef = firebase
			.storage()
			.ref(`${user.displayName}/` + 'images/' + postFile.name);
		if (postFile) {
			storageRef.put(postFile);
		}
		setInput('');
		setPostImage('');
		setPostFile('');
		removeImage();
	};

	window.addEventListener('paste', (e) => {
		if (e.clipboardData.files.length > 0) {
			const fileInput = document.querySelector('#input');
			fileInput.files = e.clipboardData.files;
			if (e.clipboardData.files[0].type.startsWith('image/')) {
				setPreviewImage(e.clipboardData.files[0]);
				setPostFile(e.clipboardData.files[0]);
			}
		}
	});

	function setPreviewImage(file) {
		const fileReader = new FileReader();
		fileReader.readAsDataURL(file);
		fileReader.onload = () => {
			document.querySelector('#imagePreview').src = fileReader.result;
			setPostImage(fileReader.result);
		};
		document.getElementById('cancelImage').style.visibility = 'visible';
	}

	const removeImage = (e) => {
		document.querySelector('#imagePreview').src = '';
		document.getElementById('cancelImage').style.visibility = 'hidden';
		setPostImage('');
	};

	return (
		<div className="postBox">
			<div className="postBox__avatar">
				<Avatar src={user.photo} />
			</div>
			<div className="postBox__form">
				<div className="postBox__inputField">
					<FormControl>
						<InputLabel>Send a message!</InputLabel>
						<Input
							className="postBox__input"
							multiline
							rowsMax={12}
							onChange={(e) => setInput(e.target.value)}
							value={input}
							type="text"
							inputProps={{ maxLength: 140 }}
							name="text"
							id="input"
						/>
					</FormControl>
					<div>
						<IconButton
							onClick={removeImage}
							id="cancelImage"
							visability="hidden"
							className="postBox__cancelImage"
						>
							<CancelIcon></CancelIcon>
						</IconButton>
						<img id="imagePreview" className="postBox__image"></img>
					</div>
				</div>
				<div className="postBox__bottomBar">
					<div>
						<Button
							disabled={!input}
							onClick={sendPost}
							type="submit"
							className="postBox__postButton"
						>
							Send
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default PostBox;
