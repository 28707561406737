import React, { useState, useEffect } from 'react';
import './Messages.css';
import Chat from './Chat';
import { Link } from 'react-router-dom';
import db from './firebase';
import { useSelector } from 'react-redux';
import { selectUser } from './features/userSlice';
import firebase from 'firebase';

function Messages() {
	const [rooms, setRooms] = useState([]);
	const user = useSelector(selectUser);

	useEffect(() => {
		db.collection('users')
			.doc(`@${user.displayName}`)
			.collection('messagesWith')
			.orderBy('timestamp', 'desc')
			.onSnapshot((snapshot) => {
				setRooms(
					snapshot.docs.map((doc) => ({ id: doc.id, data: doc.data() }))
				);
			});
	}, []);

	const createChat = () => {
		const roomName = prompt('Who would you like to start a message with?');

		db.collection('users').onSnapshot((snapshot) => {
			const userCheck = snapshot.docs.find(
				(doc) => doc.id === `@${roomName}`
			);
			if (userCheck) {
				db.collection('users')
					.doc(`@${user.displayName}`)
					.collection('messagesWith')
					.doc(`@${roomName}`)
					.set({
						timestamp: firebase.firestore.FieldValue.serverTimestamp(),
					});
			}
			
	})};

	return (
		<div className="messages">
			<div className="feed__header">
				<h2>Messages</h2>
			</div>
			<div className="messages__newChat" onClick={createChat}>
				<h2>Start a new message...</h2>
			</div>
			{rooms.map((room) => (
				<Chat key={room.id} id={room.id} timestamp={room.data.timestamp} name={room.id}/>
			))}
		</div>
	);
}

export default Messages;
