import React, { forwardRef } from 'react';
import './Post.css';
import { Avatar } from '@material-ui/core';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import RepeatIcon from '@material-ui/icons/Repeat';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import PublishIcon from '@material-ui/icons/Publish';
import IconButton from '@material-ui/core/IconButton';
import { Link } from 'react-router-dom';

const Post = forwardRef(
	(
		{ timestamp, displayName, username, verified, text, image, avatar },
		ref
	) => {
		return (
			<div className="post" ref={ref}>
				<div className="post__avatar">
					<Avatar src={avatar} />
				</div>
				<div className="post__body">
					<div className="post__header">
						<div className="post__headerText">
							<h3>
								{displayName}{' '}
								<span className="post__headerSpecial">
									{verified && <VerifiedUserIcon className="post__badge" />} @
									{username}
								</span>
								<span className="post__timeStamp">
									{new Date(timestamp?.toDate()).toUTCString()}
								</span>
							</h3>
						</div>
						<div className="post__headerDescription">
							<p>{text}</p>
						</div>
					</div>
					<img src={image} alt="" />
					<div className="post__footer">
						<Link to="/comment">
							<IconButton className="icon__comment">
								<ChatBubbleOutlineIcon fontSize="small" />
							</IconButton>
						</Link>
						<IconButton className="icon__repeat">
							<RepeatIcon fontSize="small" />
						</IconButton>
						<IconButton className="icon__like">
							<FavoriteBorderIcon fontSize="small" />
						</IconButton>
						<IconButton className="icon__share">
							<PublishIcon fontSize="small" />
						</IconButton>
					</div>
				</div>
			</div>
		);
	}
);

export default Post;
